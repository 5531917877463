<template>
  <div class="shipping-operations">
    <template v-if="!offerLoading && operations.length != 0">
      <div
        v-for="operation in operations"
        :key="operation.id"
        class="operation d-flex justify-content-between align-items-center p-y-18 p-x-14 m-b-14"
        :class="[operation.balance_operation == 'withdraw' ? 'up' : 'down']"
      >
        <div class="d-flex justify-content-start align-items-center gap-12">
          <div
            class="icon radius-full"
            :class="[
              operation.balance_operation == 'withdraw'
                ? 'main-color-bg-with-opacity'
                : 'blue-color-bg-with-opacity',
            ]"
          >
            <img
              :src="
                require(`./${
                  operation.balance_operation == 'withdraw' ? 'up' : 'down'
                }.svg`)
              "
            >
          </div>

          <p
            class="medium-font fs-20 light-gray-color-text"
            v-html="operation.operation_name"
          />
        </div>

        <div class="d-flex justify-content-end align-items-center gap-12">
          <p class="fs-18 light-gray-color-text">
            {{ $t(operation.action_date_time) }}
          </p>
          <div
            class="medium-font fs-22 text-center p-y-10 p-x-28 radius-24"
            :class="[
              operation.balance_operation == 'withdraw'
                ? 'main-color-bg-with-opacity main-color-text'
                : 'blue-color-bg-with-opacity blue-color-text',
            ]"
          >
            {{ operation.amount }}
          </div>
        </div>
      </div>
    </template>

    <template v-if="offerLoading">
      <ShippingLoader />
    </template>

    <pagination
      v-if="operations.length"
      v-model="page"
      :options="{ chunk: 3 }"
      :records="paginationNumberPages"
      :per-page="10"
      @paginate="goToCurrentIndex"
    />
  </div>
</template>

<script>
import userSetting from '../../../../services/userSetting'
import Pagination from 'v-pagination-3'
import ShippingLoader from './shippingLoader.vue'

export default {
  name: 'ShippingOperations',
  components: { Pagination, ShippingLoader },
  data () {
    return {
      offerLoading: true,
      operations: [],
      page: 1,
      paginationNumberPages: 0
    }
  },
  created () {
    this.getOrders()
  },
  methods: {
    getOrders () {
      const payload = {
        page: this.page,
        token: this.getUserToken
      }
      this.offerLoading = true
      userSetting.getUserOrderHistory(payload).then((res) => {
        this.operations = res.data.credits
        this.paginationNumberPages = res.data.paginate.total
        this.offerLoading = false
      })
    },

    goToCurrentIndex (page) {
      this.page = page
      this.offerLoading = true
      this.operations = []
      this.getOrders()
    }
  }
}
</script>

<style lang="scss" scoped>
@import './shipping-operations';
</style>
