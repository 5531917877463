<template>
  <div>
    <div
      v-for="operation in 5"
      :key="operation.id"
      class="operation d-flex justify-content-between align-items-center p-y-18 p-x-14 m-b-14"
    >
      <div class="d-flex justify-content-start align-items-center gap-12">
        <!-- <VueSkeletonLoader
          v-show="offerLoading"
          class="icon radius-full"
          type="rect"
          :width="40"
          :height="40"
          animation="wave"
        /> -->

        <!-- <VueSkeletonLoader
          v-show="offerLoading"
          class="medium-font fs-20 light-gray-color-text"
          type="rect"
          :width="100"
          :height="30"
          animation="wave"
        /> -->
      </div>

      <div class="d-flex justify-content-end align-items-center gap-12">
        <p class="fs-18 light-gray-color-text">
          <!-- <VueSkeletonLoader
            v-show="offerLoading"
            type="rect"
            :width="70"
            :height="30"
            animation="wave"
          /> -->
        </p>
        <!-- <VueSkeletonLoader
          v-show="offerLoading"
          class="medium-font fs-22 text-center p-y-10 p-x-28 radius-24"
          type="rect"
          :width="70"
          :height="30"
          animation="wave"
        /> -->
      </div>
    </div>
  </div>
</template>

<script>
// import VueSkeletonLoader from 'skeleton-loader-vue'

export default {
  name: 'ShippingLoader',
  // components: { VueSkeletonLoader },

  data () {
    return {
      offerLoading: false
    }
  },
  mounted () {
    this.offerLoading = true
  }
}
</script>

<style lang="scss" scoped>
@import './shipping-operations';
</style>
