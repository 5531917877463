<template>
  <div class="w-100">
    <ShippingOperations />
  </div>
</template>

<script>
import ShippingOperations from '@/components/profile/settings/shipping-operations/Index.vue'
export default {
  name: 'ShippingOperationsPage',
  components: { ShippingOperations }
}
</script>
